import React, { ReactElement } from 'react'
import { getPage } from '@/themes'
import Template from '@/components/app/Template'
import { HomePageProps } from '@/types/pages/home'

export default function Serps(props: HomePageProps): ReactElement {
  const SerpsPage = getPage<HomePageProps>(`Serps`)

  return (
    <Template {...props}>
      <SerpsPage {...props} />
    </Template>
  )
}
